
    import {computed, defineComponent, onMounted, reactive, toRefs} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";

    interface IState {
        list: any,
        visible: any,
        title: any,
        salary_value: any,
        total: any,
        searches: any,
        editId: any,
        type: any
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                salary_value: '',
                list: [],
                total: 0,
                searches: {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keywords: ''
                },
                editId: '',
                type: ''
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '值',
                    dataIndex: 'salary_value',
                    key: 'salary_value',
                },
                // {
                //     title: '排序',
                //     dataIndex: 'sort',
                //     key: 'sort',
                // },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '最后修改时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width: 150
                },
            ]

            const add = () => {
                state.title = '新增薪资范围'
                state.salary_value = ''
                state.visible = true
                state.type = 1
            }

            const edit = (val) => {
                state.type = 2
                state.editId = val.id
                state.salary_value = val.salary_value
                state.title = '编辑薪资范围'
                state.visible = true
            }
            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));


            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiTalent.wagesList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data.data
                    state.total = data.total
                }
            }


            const handleOk = async () => {
                if (state.type == 1) {
                    const params = {salary_value: state.salary_value}
                    const res = await apiTalent.wagesAdd(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    }
                } else {
                    const params = {salary_value: state.salary_value, id: state.editId}
                    const res = await apiTalent.wagesEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible = false
                        await getList()
                    }
                }
            }

            const onDel = async (val) => {
                const params = {id: val.id}
                const res = await apiTalent.wagesDel(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success('删除成功')
                        await getList()
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                state.searches.page = 1
                getList()
            }

            const onReset = () => {
                state.searches = {
                    page: PageUtil.pageNo,
                    limit: PageUtil.pageSize,
                    keywords: ''
                }
                getList()
            }
            onMounted(() => {
                getList()
            })

            return {
                ...toRefs(state),
                columns,
                handleOk,
                onSearch,
                onReset,
                onDel,
                edit,
                add,
                pagination,
                handleTableChange
            }
        }
    });
